import { enCommon, frCommon } from './common';
export const en = {
  translation: {
    NoAccountWithThisEmailExists: 'No account with this phone number exists',
    PleaseProvideYourEmailToReceivePaymentNotifications:
      'Please provide your phone number to receive payment notifications',
    WeveSentAVerificationCodeToYourEmail:
      "We've sent a verification code to your phone number",
    VerifyEmail: 'Verify Phone Number',
    ...enCommon,
  },
};

export const fr = {
  translation: {
    NoAccountWithThisEmailExists:
      "Aucun compte avec ce numéro de téléphone n'existe",
    PleaseProvideYourEmailToReceivePaymentNotifications:
      'Veuillez fournir votre numéro de téléphone pour recevoir les notifications de paiement',
    WeveSentAVerificationCodeToYourEmail:
      'Nous avons envoyé un code de vérification à votre numéro de téléphone',
    VerifyEmail: 'Numéro de téléphone de vérification',
    ...frCommon,
  },
};
