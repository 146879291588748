import React from 'react';
import { Avatar, Box, Tab, Tabs, Typography } from '@mui/material';
import { useHistory } from 'react-router';
import _ from 'lodash';

import { useQuery } from 'src/hooks/app/useQuery';
import { RoutePath } from 'src/routes/path';
import { useGetProfile } from 'src/hooks/queries/useAuth';
import { useGetKycStatus } from 'src/hooks/queries/useKyc';
import { KycStatus } from 'src/Types/Kyc';
import { getFullName } from 'src/utils/string';
import { AccountKycStatus } from 'src/Features/core/Common';
import { envEnum, getEnv } from 'src/utils/env';
import { useTranslation } from 'react-i18next';
import { useSettingsStore } from 'src/stores/settings';
type Props = {
  children: React.ReactNode;
};

export type TabName = 'infomation' | 'kyc';

const ProfileLayout = ({ children }: Props) => {
  const { kyc } = useSettingsStore();
  const { t } = useTranslation();
  const history = useHistory();
  const query = useQuery();
  const tab = query.get('tab') || 'infomation';
  const version = getEnv(envEnum.VERSION);

  const { data } = useGetProfile();
  const { data: kycStatus } = useGetKycStatus();

  const [value, setValue] = React.useState<TabName>(tab as TabName);

  const handleChange = (_event: React.SyntheticEvent, newValue: TabName) => {
    setValue(newValue);
    history.push(`${RoutePath.PROFILE}?tab=${newValue}`);
  };

  return (
    <Box>
      <Box display='flex' flexDirection='column' alignItems='center'>
        <Box mb={2}>
          <Avatar sx={{ width: 120, height: 120 }} />
        </Box>
        <Box textAlign='center'>
          <Typography>{getFullName(data)}</Typography>
          <Typography variant='subtitle1'>{data?.email}</Typography>
          {kyc.enabled == true && (
            <AccountKycStatus
              status={_.get(kycStatus, 'reviewStatus', '') as KycStatus}
            />
          )}
        </Box>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
          centered
        >
          <Tab label={t('information')} value='infomation' />
          {kyc.enabled == true && <Tab label='KYC' value='kyc' />}
        </Tabs>
      </Box>
      {children}
    </Box>
  );
};

export default ProfileLayout;

