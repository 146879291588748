import React, { useState } from 'react';
import * as yup from 'yup';
import { Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { TextInput } from 'src/components/Inputs';
import { getError, getErrorMessage } from 'src/utils/common';
import { useBuyCurrencyStore } from 'src/stores/buyCurrency';
import { toastWarning } from 'src/utils/toast';
import {
  usePreparePayment,
  useVerifyWalletAddress,
} from 'src/hooks/queries/useCurrency';
import { useGetKycStatus } from 'src/hooks/queries/useKyc';
import { VerifyWalletAddress } from 'src/Types/Currency';
import CardWrapper from 'src/components/Common/CardWrapper';
import { useTranslation } from 'react-i18next';
import ModalKyc from './ModalKyc';
import { getCountry } from 'src/utils/localStorage';
import { envEnum, getEnv } from 'src/utils/env';
const formSchema = yup.object().shape({
  address: yup.string().required('Required.'),
});
import _ from 'lodash';
import { useSettingsStore } from 'src/stores/settings';

export const EnterAddress = () => {
  const { i18n, t } = useTranslation();
  const [isOpenKycModal, setIsOpenKycModal] = useState(false);
  const country = getCountry();
  const {
    setFormState,
    onNext,
    transactionState: { from_currency, to_currency, network, spend, receive },
  } = useBuyCurrencyStore();
  const { kyc } = useSettingsStore();

  const { mutateAsync: mutateVerifyWalletAddress, isLoading } =
    useVerifyWalletAddress();
  const { mutate: mutatePreparePayment } = usePreparePayment();
  const { data: kycStatusData, refetch: refetchKycStatus } = useGetKycStatus();

  const { handleSubmit, register, formState } = useForm<VerifyWalletAddress>({
    resolver: yupResolver(formSchema),
    mode: 'all',
  });

  const handleSubmitForm = async ({ address }: VerifyWalletAddress) => {
    const res = await mutateVerifyWalletAddress({
      address,
      network: network as string,
    });

    if (res.isValid) {
      setFormState({ walletAddress: address });
      mutatePreparePayment(
        {
          from_currency: from_currency as string,
          to_currency: to_currency as string,
          network: network as string,
          address,
          amount: spend as number,
          country: country as string,
        },
        {
          onSuccess: (data) => {
            onNext();
          },
          onError: (error: any) => {
            if (
              error?.response?.data.errors[0].includes('KYC must be verified')
            ) {
              toastWarning({
                description: t('kycNotify'),
              });
              return new Promise((resolve) => {
                setTimeout(() => {
                  resolve(setIsOpenKycModal(true));
                }, 1000);
              });
            } else {
              const errorMsg = _.get(error, 'response.data.errors[0]');
              toastWarning({
                description: errorMsg || 'Something went wrong.',
              });
            }
          },
        }
      );
    }
  };

  const handleCloseKycModal = () => {
    setIsOpenKycModal(false);
    refetchKycStatus();
  };

  return (
    <Box>
      <CardWrapper>
        <ModalKyc open={isOpenKycModal} onClose={handleCloseKycModal} />
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <Box display='flex' flexDirection='column' alignItems='center'>
            <Typography variant='h4' fontWeight='bold' mt={4}>
              {t('Wallet')}
            </Typography>
          </Box>
          <Box mt={2}>
            <TextInput
              fullWidth
              size='medium'
              margin='dense'
              label={`${to_currency} ${t(
                'WalletAddress'
              )} (${network} network)`}
              placeholder='Please enter your address'
              error={getError(formState, 'address')}
              message={getErrorMessage(formState, 'address')}
              {...register('address')}
            />
          </Box>
          <Box display='flex' justifyContent='center' mt={8}>
            <LoadingButton
              variant='contained'
              type='submit'
              loading={isLoading}
              fullWidth
            >
              {t('Continue')}
            </LoadingButton>
          </Box>
        </form>
      </CardWrapper>
    </Box>
  );
};

export default EnterAddress;

